import React from "react";
import BaseLayout from "../components/baseLayout";
import HeroTitle from "../components/heroTitle";
import { useStaticQuery, graphql } from "gatsby";
import { Partner } from "../components/partner";

export default function Partners() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//content/partners//" } }
        sort: { order: ASC, fields: [frontmatter___tag] }
      ) {
        edges {
          node {
            frontmatter {
              tag
            }
          }
        }
      }
    }
  `);

  return (
    <BaseLayout>
      <HeroTitle title="Partners" />
      <div className="container">
        <div className="section">
          <div className="columns is-multiline is-centered is-mobile">
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <Partner partnerTag={node.frontmatter.tag} key={index} />
            ))}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
